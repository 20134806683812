import { render, staticRenderFns } from "./PendaftaranMobile.vue?vue&type=template&id=1c61cf61&scoped=true&"
import script from "./PendaftaranMobile.vue?vue&type=script&lang=js&"
export * from "./PendaftaranMobile.vue?vue&type=script&lang=js&"
import style0 from "./PendaftaranMobile.vue?vue&type=style&index=0&id=1c61cf61&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c61cf61",
  null
  
)

export default component.exports
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QLinearProgress from 'quasar/src/components/linear-progress/QLinearProgress.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBtnGroup from 'quasar/src/components/btn-group/QBtnGroup.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QRadio from 'quasar/src/components/radio/QRadio.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QField from 'quasar/src/components/field/QField.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QExpansionItem,QCard,QCardSection,QSeparator,QBtn,QDialog,QLinearProgress,QBadge,QInput,QBtnGroup,QIcon,QPopupProxy,QDate,QRadio,QPageContainer,QField});qInstall(component, 'directives', {ClosePopup});
